
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './parts/_form.vue';
import TitleReturn from '../../components/TitleReturn.vue';

import CustomersModule from '@/store/customers';

@Component({
  components: {
    TitleReturn,
    FormBlock,
  },
})
export default class CustomerEntityAdd extends Form {
  get titlePage(): string {
    return CustomersModule.pageSettings.titleAdd;
  }

  returnBefore(): void {
    this.$router.push({ name: 'customers' });
  }
}
